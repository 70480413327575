.bio-container {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
}

.bio-container h1 {
    margin-bottom: 20px;
}

p {
    margin: 0 auto;
    font-size: 90%;
    width: 90%; /* Fixed width */
    text-align: justify; /* Optional: Justifies the text so it aligns on both left and right */
}

.bio-container a {
    color: white;
}

.bio-container a:hover {
    color: #ADD8E6; /* Light blue, for example. Choose any hover color you like. */
    text-shadow: 1px 1px 8px rgba(255,255,255,0.7); /* Adds a subtle glow effect */
}

.bio-container ul {
    list-style-type: none;
    padding: 0;
}
